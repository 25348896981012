// @flow
import * as React from 'react';
import { event, select } from 'd3';
import {
    Box,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    InputBase,
    NativeSelect,
    Typography,
    makeStyles,
    Card
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import { BarChart, LegendHorizontalDiscrete, SimpleLegend } from '@geostreams/core/src/components/d3';
import ScatterPlotCustom from './ScatterPlotCustom';
import ScatterPlot from '@geostreams/geostreaming/src/components/vega/ScatterPlot';
import Carousel from '@geostreams/core/src/components/Carousel';
import { entries } from '@geostreams/core/src/utils/array';
import { useElementRect } from '@geostreams/core/src/utils/hooks';
import datstorythumbnail from '../../images/datastory.png';
import annualYieldData from '../../data/annual_yield.json';
import annualLoadData from '../../data/annual_load.json';

import tn_sitedata from '../../data/tennessee_alldata_merged_v2.json';

import huc8_names from '../../data/huc8names.json';
import Details from '../Data-Stories/Details';
import {
    getNutrientValueCategoryIndex,
    FEATURE_STYLE_INFO,
    BOUNDARIES,
    VARIABLES_INFO, HUC8_STYLE_INFO_LEGEND, TRENDS_STYLE_INFO
} from './config';


type Props = {
    regionLabel: string | null;
    featureId: string | null;
    hoverId: string | null;
    selectedBoundary: string;
    selectedNutrient: string;
    selectedYear: number;
    handleBoundaryChange: Function;
    handleVariableChange: Function;
}
const dataStories = [ {
    title: 'Evaluating Nitrogen and Phosphorus Loads in Mississippi',
    slides: "https://docs.google.com/presentation/d/e/2PACX-1vRPLZUNhABEekztpPbtNtop0r3yRdBJra5zLnayrVsFZVepFfGXztM288LfgNb7BapUF6Ux06TbIEH4/embed?start=true&loop=false&delayms=30000" ,
    thumbnail: datstorythumbnail
},]
const useStyle = makeStyles((theme) => ({
    dropdownsContainer: {
        background: '#e2ebf4'
    },
    header: {
        margin: '10px auto'
    },
    divider: {
        borderTop: '1px dashed #000',
        backgroundColor: 'unset'
    },
    infoIcon: {
        color: '#0D73C5',
        fontSize: '1rem'
    },
    featureProp: {
        color: '#E05769'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    formLabel: {
        padding: theme.spacing(1),
        fontSize: '.88rem'
    },
    selectButton: {
        'background': theme.palette.primary.main,
        'borderRadius': 4,
        'color': theme.palette.primary.contrastText,
        'position': 'relative',
        'height': 42,
        'padding': theme.spacing(2),
        'fontSize': '.75rem',
        '&:focus': {
            borderRadius: 4
        },
        '& option': {
            color: 'initial'
        }
    },
    annualFlowChart: {
        marginTop: -75
    },
    annualFlowLegend: {
        '& svg': {
            fontSize: '.8rem',
            padding: 5,
            border: '1px solid #aaa'
        }
    },
    barChart: {
        '& .xAxis .tick:nth-child(2n) text': {
            visibility: 'hidden'
        }
    },
    annualYieldTooltip: {
        height: 15
    },
    chartTooltip: {
        position: 'fixed',
        background: '#fff',
        border: '1px solid #eee',
        borderRadius: 5,
        padding: 5,
        opacity: 0
    },
    carousel: {
        width: '100%',
        marginBottom: 20
    },
    carouselButton: {
        'backgroundColor': '#0D73C5',
        '&:hover': {
            backgroundColor: '#0D73C5'
        }
    },
    carouselSlideContainer: {
        width: '100%'
    },
    carouselSlide: {
        width: '100%'
    }
}));

const Sidebar = ({
    regionLabel,
    featureId,
    hoverId,
    selectedBoundary,
    selectedNutrient,
    selectedYear,
    handleBoundaryChange,
    handleVariableChange
}: Props) => {
    const classes = useStyle();

    const container = React.useRef();
    const containerRect = useElementRect(container);

    const annualHUCChartTooltipRef: { current: null | HTMLDivElement } = React.createRef();
    const annualWaterChartTooltipRef: { current: null | HTMLDivElement } = React.createRef();

    const annualLoadChartData = annualLoadData[featureId];

    const yearsOptions = [];
    let annualYieldChartData;
    let featureValue;
    if (annualYieldData[selectedNutrient] && annualYieldData[selectedNutrient][featureId]) {
        featureValue = annualYieldData[selectedNutrient][featureId][selectedYear];
        annualYieldChartData = Object
            .entries(annualYieldData[selectedNutrient][featureId])
            .map(
                ([year, value]) => {
                    // Data is already sorted by year in `src/data/annual_yield.json`
                    yearsOptions.push(<option key={year} value={year}>{year}</option>);
                    return {
                        x: year,
                        y: value,
                        selected: +year === +selectedYear
                    };
                }
            );
    };

    let hucChartData;
    let ticks;
    if (tn_sitedata[featureId] && tn_sitedata[featureId][selectedNutrient]) {
        hucChartData = tn_sitedata[featureId][selectedNutrient];
        // Verify dates are chronological for chart
        const dates = [];
        const lookup = {};
        const sorted = [];
        hucChartData.forEach(entry => {
            // Put year first bc it is sorting "alphabetically"
            const ordered_date = entry.x.split(" ")[0].split("/")[2] + "/" + entry.x.split("/")[0] + "/" + entry.x.split("/")[1];
            const month = entry.x.split(" ")[0].split("/")[0] + "/" + entry.x.split(" ")[0].split("/")[2]
            if (!dates.includes(ordered_date))
                dates.push(ordered_date);
            lookup[ordered_date] = {x: month, y: entry.y};
        });
        dates.sort();

        const firstDate = dates[0];
        const lastDate = dates[dates.length-1];
        const firstYear = firstDate.split("/")[0]
        const firstMonth = firstDate.split("/")[1]
        const firstDay = firstDate.split("/")[2]
        const lastYear = lastDate.split("/")[0]
        const lastMonth = lastDate.split("/")[1]
        const lastDay = lastDate.split("/")[2]

        const fullDates = [];
        for (var yy=firstYear; yy<=lastYear; yy++) {
            for (var mm=1; mm<=12; mm++) {
                if ((yy==firstYear && mm<firstMonth) || (yy==lastYear && mm>lastMonth))
                    continue;
                for (var dd=1; dd<=31; dd++) {
                    if ((mm==2 && dd>28) || (mm in [4,6,9,11] && dd>30) || (dd>31))
                        continue
                    fullDates.push(yy+"/"+("0"+mm).slice(-2)+"/"+("0"+dd).slice(-2));
                }
            }
        }

        fullDates.forEach(entry => {
            if (lookup[entry] != null) {
                sorted.push({
                    x: entry,
                    y: lookup[entry].y
                })
            }
        });
        hucChartData = sorted;
    }

    const [iframeProps, updateIframeProps] = React.useState({});

    const [dialogContent, updateDialogContent] = React.useState < null | {
        title: string;
        description: string | React.Node;
    } > (null);
    const handleModalClose = () => updateIframeProps({});
    return (
        <>
            <Box
                className={classes.dropdownsContainer}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <FormControl
                    component="fieldset"
                    className={classes.formControl}
                >
                    <FormLabel
                        component="legend"
                        className={classes.formLabel}
                    >
                        <Box display="flex" alignItems="center">
                            Boundary Type
                            &nbsp;
                            <InfoIcon
                                className={`actionIcon ${classes.infoIcon}`}
                                onClick={(() => updateDialogContent(VARIABLES_INFO.boundary))}
                            />
                        </Box>
                    </FormLabel>
                    <NativeSelect
                        className={classes.selectButton}
                        value={selectedBoundary}
                        onChange={({ target: { value } }) => {
                            handleBoundaryChange(value);
                        }}
                        input={<InputBase />}
                    >
                        {entries(BOUNDARIES).map(([name, { label }]) => (
                            <option
                                key={name}
                                value={name}
                            >
                                {label}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>
                <FormControl
                    component="fieldset"
                    className={classes.formControl}
                >
                    <FormLabel
                        component="legend"
                        className={classes.formLabel}
                    >
                        <Box display="flex" alignItems="center">
                            Nutrient
                            &nbsp;
                            <InfoIcon
                                className={`actionIcon ${classes.infoIcon}`}
                                onClick={(() => updateDialogContent(VARIABLES_INFO.nutrient))}
                            />
                        </Box>
                    </FormLabel>
                    <NativeSelect
                        className={classes.selectButton}
                        value={selectedNutrient}
                        onChange={({ target: { value } }) => {
                            handleVariableChange(value, 'nutrient');
                        }}
                        input={<InputBase />}
                    >
                        <option value="Phosphorus">Phosphorus</option>
                        <option value="Nitrogen">Nitrogen</option>
                    </NativeSelect>

                </FormControl>

            </Box>

            <Container ref={container}>
                <Typography
                    className={classes.header}
                    variant="h5"
                >
                    {regionLabel} - <span className={classes.featureProp}>{featureId}</span>
                </Typography>


                {featureValue !== undefined ?
                    <>
                    </> :
                    null}

                {selectedBoundary === 'drainage' ?
                    <Typography variant="subtitle2" align="center" gutterBottom>
                        <Container>
                            <LegendHorizontalDiscrete
                                boxCount={3}
                                boxWidth={((window.innerWidth / 3) - 150) / 3}
                                getBoxInfo={(idx) => TRENDS_STYLE_INFO[idx]}
                            />
                        </Container>

                        <p>Descriptions can go here.</p>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.tn.gov/content/dam/tn/environment/water/tmdl-program/wr-ws_tennessee-draft-nutrient-reduction-framework_030315.pdf"
                        >

                            Tennessee Nutrient Reduction Framework
                        </a>
                    </Typography> : null}

                {selectedBoundary === 'sf' && featureId !== undefined ?
                    <Typography variant="subtitle2" align="center" gutterBottom>
                        <p> {String(featureId.split('-')[1]) !== "undefined" ? <p>
                            To view measurements for this station, click here:<br/>
                            <a href={"https://waterdata.usgs.gov/monitoring-location/"+featureId.split('-')[1]}>
                            Link to Data
                            </a>
                        </p> : 'Select a Station'} </p>
                    </Typography> : null
                }

                {selectedBoundary === 'huc8' && featureId !== undefined ?
                    <Typography variant="subtitle2" align="center" gutterBottom>
                        <p> {hucChartData ? 'Concentrations' : 'Select a Station'} </p>

                        {hucChartData ? <ScatterPlotCustom
                            className={classes.barChart}
                            plotData={
                                hucChartData.map((entry) => ({
                                    x: new Date(entry.x.split(' ')[0]),
                                    y: entry.y,
                                    z: entry.x.split(' ')[0]
                                }))
                            }
                            xAxisProps={{
                                title: 'Date',
                                titlePadding: 50,
                                stroke: '#4682b4',
                                strokeWidth: 2,
                            }}
                            yAxisProps={{
                                title: 'Measurement',
                                titlePadding: 80,
                                stroke: '#4682b4',
                                strokeWidth: 2
                            }}
                            mouseOver={(d) => {
                                select(annualHUCChartTooltipRef.current)
                                    .html(`${d.z}<br/>${d.y} mg/L`)
                                    .transition()
                                    .duration(200)
                                    .style('opacity', .9)
                                    .style('left', `${event.clientX}px`)
                                    .style('top', `${event.clientY - 100}px`);
                            }}
                            mouseOut={() => {
                                select(annualHUCChartTooltipRef.current)
                                    .transition()
                                    .duration(500)
                                    .style('opacity', 0);
                            }}
                            barStroke={(d) => yearsOptions.length && d.selected ? 'red' : '#117fc9'}
                            barStrokeWidth={2}
                            barStrokeOpacity={(d) => d.selected ? 1 : 0}
                            barFill="#117fc9"
                            barFillOpacity="1"
                            lineStroke="#f63700"
                            lineStrokeWidth={2}
                            intervalFill="#fdb47f"
                            width={(containerRect.width || 0) * 0.9}
                            height={300}
                            marginTop={50}
                            marginBottom={60}
                            marginLeft={60}
                            marginRight={20}
                        /> : null}
                        <div ref={annualHUCChartTooltipRef} className={classes.chartTooltip} />
                        <div>
                            <b>DATA AVAILABILITY</b>
                            <br></br>
                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="2" fill="white" stroke="black" />
                            </svg> Less than 2 years
                            <br></br>
                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="4" fill="#93BDD4" stroke="black" />
                            </svg> 2-5 years
                            <br></br>
                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="6" fill="#4D94C1" stroke="black" />
                            </svg> 5-10 years
                            <br></br>
                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="8" fill="#1B64A7" stroke="black" />
                            </svg> 10-20 years
                            <br></br>
                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#062D64" stroke="black" />
                            </svg> 20+ years
                        </div>
                    </Typography> : null}
                    <Divider/>
                      <Typography
                        className={classes.header}
                        variant="h6"
                        noWrap
                        gutterBottom
                    >
                        Nutrient Taskforce Working Group
                    </Typography>
                    <Typography>
                        <a href="https://www.tn.gov/environment/program-areas/wr-water-resources/nutrient-management-in-tennessee/partnerships/nutrient-task-force-work-groups.html">
                            Monitoring and Data Analysis Report
                        </a>
                    </Typography>
                <Typography
                    className={classes.header}
                    variant="h6"
                    noWrap
                    gutterBottom
                >
                    Data Source
                </Typography>
                <Typography>
                    Concentration data downloaded from the <a href="https://www.waterqualitydata.us/">Water Quality Portal</a>.
                    <br/><br/>
                    Total Nitrogen and Total Phosphorus used where available. Where Total Nitrogen is unavailable, gaps filled (if possible) by combining "Nitrate + Nitrite", "Kjeldahl nitrogen", and "Inorganic nitrogen (nitrate and nitrite) ***retired***use Nitrate + Nitrite" characteristics.
                </Typography>
            </Container>

            {dialogContent ?
                <Dialog open onClose={() => updateDialogContent(null)}>
                    <DialogTitle disableTypography>
                        <Typography variant="h6" align="center">
                            {dialogContent.title}
                            <IconButton
                                className="right noPadding actionIcon"
                                onClick={() => updateDialogContent(null)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        {dialogContent.description}
                    </DialogContent>
                </Dialog> :
                null}
              <>
         <Details {...iframeProps} handleClose={handleModalClose} />
        </>
        </>
    );
};

export default Sidebar;
