import * as React from "react";
import { format } from "d3";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
  Tooltip,
  DeleteIcon,
  IconButton,
} from "@material-ui/core";
import DownTrendIcon from "@material-ui/icons/ArrowDropDown";
import UpTrendIcon from "@material-ui/icons/ArrowDropUp";
import FlatTrendIcon from "@material-ui/icons/FiberManualRecord";
import Control from "@geostreams/core/src/components/ol/Control";
import {
  createEmpty as createEmptyExtent,
  extend as extendExtent,
} from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import GroupLayer from "ol/layer/Group";
import ImageLayer from "ol/layer/Image";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import ImageWMSSource from "ol/source/ImageWMS";
import OSM, { ATTRIBUTION as OSM_ATTRIBUTION } from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { decode } from "geobuf";
import Pbf from "pbf";
import { Map, BaseControlPortal } from "@geostreams/core/src/components/ol";
import { entries } from "@geostreams/core/src/utils/array";
import { SLRSlope } from "@geostreams/core/src/utils/math";
import ol from "openlayers";
import type {
  Feature as FeatureType,
  Map as MapType,
  MapBrowserEventType,
} from "ol";
import type { Layer as LayerType } from "ol/layer";

import annualYieldData from "../../data/annual_yield.json";
import overallData from "../../data/overall_data.json";
import { HEADERS_HEIGHT } from "../Layout/Header";
import { fromLonLat, toLonLat } from "ol/proj.js";
import bootstrap from "bootstrap/dist/css/bootstrap.css";
import Overlay from "ol/Overlay.js";
import Sidebar from "./Sidebar";
import {
  CONTEXTUAL_LAYERS,
  MAP_BOUNDS,
  BOUNDARIES,
  GEOSERVER_URL,
  getLayerExtent,
  getOverallFeatureLabels,
  getFeatureStyle,
  initialState,
  getHUC8Style,
  getWaterQualityStyle,
} from "./config";
import { toStringHDMS } from "ol/coordinate.js";
import ReactDOM from "react-dom";

import Popover from "react-bootstrap/Popover";
const styles = {
  main: {
    height: `calc(100% - ${HEADERS_HEIGHT}px)`,
  },
  mainContainer: {
    position: "absolute",
    height: "100%",
  },
  sidebar: {
    height: "100%",
    overflowY: "auto",
    "& a": {
      color: "#0D73C5",
    },
  },
  fillContainer: {
    width: "100%",
    height: "100%",
  },
  trendIcon: {
    fontSize: 18,
    "&.red": {
      color: "#ff0000",
    },
    "&.blue": {
      color: "#1e90ff",
    },
    "&.black": {
      color: "#000",
    },
  },
  boundaryInfoControl: {
    top: "0.5em",
    left: "3em",
    background: "#fff",
    border: "2px solid #aaa",
    paddingTop: 10,
  },
  legendControl: {
    bottom: "0.5em",
    left: "0.5em",
    background: "#fff",
    border: "2px solid #aaa",
  },
  legendItem: {
    padding: "0 8px",
  },
  legendItemIcon: {
    minWidth: 25,
  },
};

type Props = {
  classes: {
    main: string,
    mainContainer: string,
    sidebar: string,
    fillContainer: string,
    trendIcon: string,
    boundaryInfoControl: string,
    legendControl: string,
    legendItem: string,
    legendItemIcon: string,
  },
};

type State = {
  boundary: string,
  featureId: string | null,
  hoverId: string | null,
  regionLabel: string | null,
  selectedFeature: FeatureType | null,
  year: number,
  nutrient: string,
};

class Home extends React.Component<Props, State> {
  map: MapType;

  boundaryInfoControl: Control;

  legendControl: Control;

  layers: {
    [key: string]: LayerType,
  };

  legends: Array<{
    layerId: string,
    title: string,
    url: string,
    boundaries?: Array<string>,
    visible: boolean,
  }>;

  constructor(props) {
    super(props);

    const [regionLabel, featureId] = getOverallFeatureLabels("huc8");
    this.state = {
      featureId,
      regionLabel,
      selectedFeature: new ol.source.Vector(),
      ...initialState,
    };

    this.boundaryInfoControl = new Control({
      className: this.props.classes.boundaryInfoControl,
    });

    this.legendControl = new Control({
      className: this.props.classes.legendControl,
    });

    const geoJSONFormat = new GeoJSON({
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    this.legends = [];

    this.layers = {
      basemaps: new GroupLayer({
        title: "Base Maps",
        layers: [
          new TileLayer({
            type: "base",
            visible: true,
            title: "Carto",
            source: new XYZ({
              url: "https://{a-d}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
              attributions: [
                '&#169; <a href="https://www.carto.com">Carto</a>,',
                OSM_ATTRIBUTION,
              ],
            }),
          }),
          new TileLayer({
            type: "base",
            visible: false,
            title: "OSM",
            source: new OSM(),
          }),
        ],
      }),
      contextual: new GroupLayer({
        title: "Layers",
        layers: CONTEXTUAL_LAYERS.map(({ title, id, boundaries, zIndex }) => {
          const source = new ImageWMSSource({
            url: `${GEOSERVER_URL}/wms`,
            params: { LAYERS: id },
            ratio: 1,
            serverType: "geoserver",
          });
          const visible =
            !boundaries || boundaries.indexOf(initialState.boundary) > -1;
          const layer = new ImageLayer({
            title,
            source,
            visible,
            zIndex,
          });
          this.legends.push({
            layerId: layer.ol_uid,
            title,
            url: source.getLegendUrl(),
            boundaries,
            visible,
          });
          return layer;
        }),
      }),
      ...entries(BOUNDARIES).reduce(
        (boundaryLayers, [name, { visible, layers }]) => {
          const group = new GroupLayer({
            layers: layers.map(
              ({ url, style, interactive = false, zIndex = undefined }) => {
                const source = new VectorSource({
                  loader: (extent) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open("GET", url);
                    xhr.responseType = "arraybuffer";
                    const onError = () => {
                      source.removeLoadedExtent(extent);
                    };
                    xhr.onerror = onError;
                    xhr.onload = () => {
                      if (xhr.status === 200) {
                        const geojson = decode(new Pbf(xhr.response));
                        source.addFeatures(geoJSONFormat.readFeatures(geojson));
                      } else {
                        onError();
                      }
                    };
                    xhr.send();
                  },
                  useSpatialIndex: true,
                  format: geoJSONFormat,
                });
                const layer = new VectorLayer({
                  source,
                  name,
                  style: (feature, resolution) => {
                    const { nutrient, year } = this.state;
                    return style(feature, resolution, nutrient, year);
                  },
                });
                layer.set("interactive", interactive);
                layer.setZIndex(zIndex);
                layer.renderDeclutter = () => {};
                source.on("change", () => {
                  if (!group.isReady && source.getState() === "ready") {
                    group.isReady = true;
                    group.setVisible(visible);
                  }
                });
                return layer;
              }
            ),
          });
          boundaryLayers[name] = group;
          return boundaryLayers;
        },
        {}
      ),
    };

    const { hoverId: hoverId } = this.state;
  }

  updateMap = (map) => {
    this.map = map;
    const extent = createEmptyExtent();
    extendExtent(extent, getLayerExtent(initialState.boundary));
    this.map.getView().fit(extent, { duration: 300 });

    // change cursor when mouse is over interactive layers
    this.map.on("pointermove", (e) => {
      const pixel = map.getEventPixel(e.originalEvent);
      const feature = map.forEachFeatureAtPixel(pixel, (_, layer) => {
        return layer.get("interactive");
      });
      map.getTarget().style.cursor = feature ? "pointer" : "";
    });
  };

  handleBoundaryChange = (boundary) => {
    const { selectedFeature } = this.state;
    if (selectedFeature) {
      const { nutrient, year } = this.state;
      // TODO: Instead, add/remove red-border polygon to special Selected layer of map.
      /**selectedFeature.setStyle(
                getFeatureStyle(
                    selectedFeature,
                    null,
Expand All
	@@ -314,35 +327,37 @@ class Home extends React.Component<Props, State> {
                    false
                )
            );**/
    }
    this.layers[this.state.boundary].setVisible(false);

    this.layers[boundary].setVisible(true);
    const extent = createEmptyExtent();
    extendExtent(extent, getLayerExtent(boundary));
    this.map.getView().fit(extent, { duration: 300 });

    this.legends.forEach((legend) => {
      const { layerId, boundaries } = legend;
      const layer = this.layers.contextual
        .getLayersArray()
        .find(({ ol_uid }) => ol_uid === layerId);
      const visible = !boundaries || boundaries.indexOf(boundary) > -1;
      layer.setVisible(visible);
      legend.visible = visible;
    });

    const [regionLabel, featureId] = getOverallFeatureLabels(boundary);
    this.setState({ boundary, featureId, regionLabel, selectedFeature: null });
  };

  handleVariableChange = (value, variable) => {
    this.setState({ [variable]: value }, () => {
      this.layers[this.state.boundary]
        .getLayers()
        .forEach((layer) => layer.changed());
      const { selectedFeature } = this.state;
      if (selectedFeature) {
        const { nutrient, year } = this.state;
        /**
                    selectedFeature.setStyle(
                        getFeatureStyle(
         getSiteStyle                  selectedFeature,
Expand All
	@@ -353,92 +368,110 @@ class Home extends React.Component<Props, State> {
                        )
                    );
                     **/
      }
    });
  };

  handleMapHover = (event: MapBrowserEventType) => {
    const hoveredStationId = event.map.forEachFeatureAtPixel(
        event.pixel,
        (feature, layer) => {
          if (layer.get("interactive")) {
            return feature.get("MonitoringLocationIdentifier");
          }
          return false;
        }
    );

    const hoveredFeature = event.map.forEachFeatureAtPixel(
        event.pixel,
        (feature, layer) => {
          if (
              feature.get("MonitoringLocationIdentifier") === hoveredStationId &&
              layer.get("interactive") &&
              (feature.getGeometry().getType().indexOf("Polygon") > -1 ||
                  feature.getGeometry().getType().indexOf("Point") > -1)
          ) {
            return feature;
          }
          return false;
        },
        {
          hitTolerance: 10,
        }
    );

    if (hoveredFeature) {
      const overlayToRemove = this.map.getOverlayById("overlayId");
      if (overlayToRemove) {
        this.map.removeOverlay(overlayToRemove);
      }

      const overlay = new Overlay({
        element: document.createElement("div"),
        id: "overlayId",
      });
      this.map.addOverlay(overlay);

      const CustomOverlay = () => {
        return (
            <div className="custom-overlay">
              <Popover placement="bottom">
                <Typography sx={{ p: 2 }}>{hoveredStationId}</Typography>
              </Popover>
            </div>
        );
      };

      const pixel = this.map.getEventPixel(event.originalEvent);
      pixel[0] += 20;
      let pos = this.map.getCoordinateFromPixel(pixel);
      overlay.setPosition(pos);
      overlay.getElement().style.display = "block";
      ReactDOM.render(<CustomOverlay />, overlay.getElement());

    } else {
      const overlayToRemove = this.map.getOverlayById("overlayId");
      if (overlayToRemove) {
        this.map.removeOverlay(overlayToRemove);
      }
    }
  };

  handleMapClick = (event: MapBrowserEventType) => {
      const { featureId: previousFeatureId, selectedFeature: previousFeature } =
        this.state;

      const clickedStationId = event.map.forEachFeatureAtPixel(
        event.pixel,
        (feature, layer) => {
          if (layer.get("interactive")){ // && layer.get('name') != 'sf') {
            return feature.get("MonitoringLocationIdentifier");
          }
          return false;
        }
      );

      const selectedFeature = event.map.forEachFeatureAtPixel(
        event.pixel,
        (feature, layer) => {
          if (
            feature.get("MonitoringLocationIdentifier") === clickedStationId &&
            layer.get("interactive") &&
            (feature.getGeometry().getType().indexOf("Polygon") > -1 ||
              feature.getGeometry().getType().indexOf("Point") > -1)
          ) {
            return feature;
          }
          return false;
        },
        {
          hitTolerance: 10,
        }
      );

      if (selectedFeature) {
        const { boundary, nutrient, year } = this.state;
        const [regionLabel, overallFeatureId] =
          getOverallFeatureLabels(boundary);

        // TODO: Remove any existing polygons from top layer first, only one red outline at a time

        let geometry = selectedFeature.getGeometry();
        let feature = new ol.Feature();
        feature = new ol.Feature({
          geometry: geometry,
        });
        feature.setStyle(
          new ol.style.Style({
            stroke: new ol.style.Stroke({
              color: "red",
              width: 3,
            }),
          })
        );
        let all_map_layers = event.map.getLayers().getArray().slice();
        all_map_layers.map((map_layer) => {
          const layers_array = map_layer.values_.layers.array_;
          if (layers_array.length > 0) {
            layers_array.forEach((layer) => {
              if (layer.values_.interactive == false) {
                if (layer.getSource() == null)
                  layer.setSource(new ol.source.Vector({}));
                layer.getSource().addFeature(feature);
              }
            });
          }
        });

        /**if (previousFeatureId !== overallFeatureId && previousFeature && previousFeature.getGeometry().getType().indexOf('Polygon') > -1) {
              // Get selected layer and pick style based on that
              previousFeature.setStyle(getFeatureStyle( // HUC8
                  previousFeature,
Expand All
@@ -458,10 +491,13 @@ class Home extends React.Component<Props, State> {
              ));
          }**/

        const featureId =
          selectedFeature.get("Name") ||
          selectedFeature.get("HUC_8") ||
          selectedFeature.get("MonitoringLocationIdentifier");
        if (featureId !== previousFeatureId) {
          // Feature is selected - what kind is it?
          /**if (selectedFeature.getGeometry().getType().indexOf('Polygon') > -1) {
                  selectedFeature.setStyle(getFeatureStyle( // HUC8
                      selectedFeature,
                      null,
Expand All
@@ -481,170 +517,169 @@ class Home extends React.Component<Props, State> {
                  }
              }**/
          this.setState({ featureId, selectedFeature });
        } else {
          // Feature is deselected
          this.setState({
            featureId: overallFeatureId,
            regionLabel,
            selectedFeature: null,
          });
        }
      }
  };

  getNutrientTrend = (nutrient: string, featureName: string): number => {
    const x = [];
    const y = [];
    Object.entries(annualYieldData[nutrient][featureName]).forEach(
      ([year, value]) => {
        x.push(parseInt(year, 10));
        y.push(parseFloat(value));
      }
    );
    return SLRSlope(x, y) || 0;
  };

  getTrends = (featureName: string) => {
    const classes = this.props.classes;
    const nitrogenTrend = this.getNutrientTrend("Nitrogen", featureName);
    const phosphorusTrend = this.getNutrientTrend("Phosphorus", featureName);
    return [
      ["Nitrogen", nitrogenTrend],
      ["Phosphorus", phosphorusTrend],
    ].map(([nutrient, trend]) => {
      let Icon;
      let color;
      if (trend > 0) {
        Icon = UpTrendIcon;
        color = "red";
      } else if (trend < 0) {
        Icon = DownTrendIcon;
        color = "blue";
      } else {
        Icon = FlatTrendIcon;
        color = "black";
      }
      return (
        <>
          <br />
          <span>
            {nutrient} Trend
            <Icon className={`${classes.trendIcon} ${color}`} />
          </span>
        </>
      );
    });
  };

  getBoundaryInfoContent = () => {
    const { selectedFeature, boundary } = this.state;
    let featureName;
    let contributingWaterways;
    let cumulativeAcres;

    if (selectedFeature) {
      featureName =
        selectedFeature.get("Name") || selectedFeature.get("STATION ID");
      const featureProps = selectedFeature.getProperties();
      contributingWaterways = featureProps.contributing_waterways;
      cumulativeAcres = featureProps.cumulative_acres;
    } else {
      featureName = getOverallFeatureLabels(boundary).join(" - ");
      contributingWaterways = ""; // overallData[boundary].contributing_waterways;
      cumulativeAcres = ""; // overallData[boundary].cumulative_acres;
    }

    return (
      <>
        <Typography variant="subtitle2" gutterBottom>
          <span>
            <b>{featureName}</b>
          </span>
        </Typography>
        <Typography variant="caption">
          {contributingWaterways ? (
            <span>
              {format(",")(contributingWaterways)} Contributing Waterways
            </span>
          ) : null}
          <br />
          {cumulativeAcres ? (
            <span>{format(",")(cumulativeAcres)} Cumulative Acres</span>
          ) : null}
        </Typography>
      </>
    );
  };

  render() {
    const { classes } = this.props;
    const { boundary, regionLabel, featureId, hoverId, nutrient, year } =
      this.state;

    return (
      <div>
        <Grid className={classes.mainContainer} container alignItems="stretch">
          <Grid className="fillContainer" item xs={8}>
            <Map
              className="fillContainer"
              zoom={7}
              minZoom={5}
              extent={MAP_BOUNDS}
              center={[-9972968, 4972295]}
              controls={[this.boundaryInfoControl, this.legendControl]}
              layers={Object.values(this.layers)}
              legends={this.legends}
              layerSwitcherOptions={{
                onShow: () => {
                  this.legends.forEach((legend) => {
                    const { title, visible } = legend;
                    document
                      .querySelectorAll(".layer-switcher li.layer")
                      .forEach((el) => {
                        if (el.innerText === title) {
                          if (visible) {
                            el.classList.remove("hidden");
                          } else {
                            el.classList.add("hidden");
                          }
                        }
                      });
                  });
                },
              }}
              updateMap={this.updateMap}
              events={{
                click: this.handleMapClick,
                pointermove: this.handleMapHover,
              }}
            ></Map>
          </Grid>
          <Grid className={classes.sidebar} item xs={4}>
            <Sidebar
              regionLabel={regionLabel}
              featureId={featureId}
              hoverId={hoverId}
              selectedBoundary={boundary}
              selectedNutrient={nutrient}
              selectedYear={year}
              handleBoundaryChange={this.handleBoundaryChange}
              handleVariableChange={this.handleVariableChange}
            />
          </Grid>
        </Grid>

        {/* {this.state.hoverId ? (
          <div>
            <div id="popover-container" style={{ padding: 1,
    backgroundColor:"red",
    boxShadow: 2 }}>
              {this.state.hoverId}
            </div>
          </div>
        ) : null} */}
      </div>
    );
  }
}

export default withStyles(styles)(Home);
