// @flow
import * as React from 'react';
import { DEVICE_PIXEL_RATIO } from 'ol/has';
import { Fill, Icon, Stroke, Style, Circle, RegularShape } from 'ol/style';

import type FeatureType from 'ol/Feature';

import huc8 from '../../data/TN_HUC8.pbf';
import drainage from '../../data/TN_Nitrate_Trend_Catchments.pbf';

// TN Water Portal
import tn_sites from '../../data/tennessee_stations_v3.pbf';
import tn_sitedata from '../../data/tennessee_alldata_merged_v2.json';
import streamflow from'../../data/USGS_TN_SW-RT-Q_TDEC.pbf';

import patternNoData from '../../images/pattern_no_data.png';


import ol from 'openlayers';
export const initialState = {
    boundary: 'huc8',
    nutrient: 'Nitrogen',
    year: 2022
};

export const GEOSERVER_URL = process.env.GEOSERVER_URL || '';

// A missing `boundaries` prop from a legend item means it will be shown for all boundary types
export const CONTEXTUAL_LAYERS: Array<{ title: string; id: string; zIndex?: number, boundaries?: Array<string> }> = [
    //{ title: 'Rivers', id: 'gltg:us-rivers', zIndex: 2 },
    //{ title: 'State Boundaries', id: 'gltg:us-states' },
    //{ title: 'HUC8 Trends', id: 'gltg_huc8-trends' },
];

export const getOverallFeatureLabels = (boundary: string) => {
    // Returns an array of two items: the first item is the active boundary label,
    // and the second item is its variable name in `data.json`, which can be used for rendering labels too.
    switch (boundary) {
        case 'drainage':
            return ['Tennessee', 'Site Locations'];
        case 'huc8':
            return ['Tennessee', 'Water Quality Portal'];
        case 'sf':
            return ['Tennessee', 'Streamflow Monitoring'];
        case 'watershed':
            return ['Mississippi River Basin', 'Nutrient Load to Gulf of Mexico'];
        default:
            return [null, null];
    }
};

export const MAP_BOUNDS = [
    -10059887.5976,
    4020628.4218,
    -9049981.2129,
    4744639.9537
];

export const getLayerExtent = (boundary: string) => {
    return MAP_BOUNDS;

    switch (boundary) {
        case 'drainage':
            return [-10673131.179092214, 4240945.513367433, -9272804.820907786, 5703644.486632567];
        case 'huc8':
            return [-10673131.179092214, 4240945.513367433, -9272804.820907786, 5703644.486632567];
        case 'sf':
            return [-10673131.179092214, 4240945.513367433, -9272804.820907786, 5703644.486632567];
        case 'watershed':
            return [-10923839.372435283, 4545502.562858378, -9523076.314751584, 6008657.686866852];
        default:
            return MAP_BOUNDS;
    }
};

export const getSiteIcon = (station_id?: string, nutrient?: string): string => {
    if (tn_sitedata[station_id] && tn_sitedata[station_id][nutrient]) {
        let records = tn_sitedata[station_id][nutrient];

        const years = [];
        records.forEach(entry => {
            const yyyy = entry.x.split("/")[2].substring(0,4);
            if (!years.includes(yyyy)) years.push(yyyy);
        });

        // Use that to determine size of the circles in stages
        let rad = 2;
        let fill = 'white';
        if (years.length > 2 && years.length <= 5) {
            rad = 4;
            fill = '#93BDD4';
        }
        if (years.length > 5 && years.length <= 10) {
            rad = 6;
            fill = '#4D94C1';
        }
        if (years.length > 10 && years.length <= 20) {
            rad = 8;
            fill = '#1B64A7';
        }
        if (years.length > 20) {
            rad = 10;
            fill = '#062D64';
        }

        return new Circle({
            radius: rad,
            fill: new Fill({color: fill}),
            stroke: new Stroke({
                color: 'black',
                width: 1
            }),
        });
    } else {
        return new Circle({
            radius: 1,
            fill: new Fill({color: 'white'}),
            stroke: new Stroke({
                color: 'black',
                width: 1
            }),
        });
    }

};

export const getSiteStyle = (
    feature: FeatureType,
    resolution: ?number,
    nutrient: string,
    year: number,
    isSelected: boolean = false
) => {
    let trendStatement;
    let hucid = feature.get("HUC");
    let station = feature.get("MonitoringLocationIdentifier");

    if (true) {
        return new Style({
            image: getSiteIcon(station, nutrient)
        });
    } else {
        return new Style({});
    }
}

export const getPlainStyle = (
    feature: FeatureType,
    resolution: ?number,
    nutrient: string,
    year: number,
    isSelected: boolean = false
) => {
    let hucid = feature.get("HUC8_N");
    const TIER_1 = [11110103,11010003,11010004,11110203,11110207,8020402,8020205];

    if (false) {
        return (
            new Style({
                fill: new Fill({
                    color: '#fdbfbf'
                }),
                stroke: new Stroke({
                    color: '#7f7f7f',
                    width: 1
                })
            })
        );
    } else {
        return (
            new Style({
                stroke: new Stroke({
                    color: '#7f7f7f',
                    width: 1
                })
            })
        );
    }
}

export const getSFStyle = (
    feature: FeatureType,
    resolution: ?number,
    nutrient: string,
    year: number,
    isSelected: boolean = false
) => {
    console.log("SF")
    console.log(feature)
    return new Style({
        image: new RegularShape({
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'black', width: 1}),
            points: 4,
            radius: 2,
            angle: Math.PI / 4,
        }),
    })
}

export type BoundaryType = {
    [key: string]: {
        visible: boolean;
        label: string;
        layers: Array<{
            url: string;
            style: Function;
            interactive?: boolean;
            zIndex?: number
        }>;
    };
}

export const BOUNDARIES: BoundaryType = {
    // new layer group
    huc8: {
        visible: true,
        label: 'Stations (Portal)',
        layers: [
            {
                url: huc8,
                style: getPlainStyle,
                interactive: true
            },
            {
                url: tn_sites,
                style: getSiteStyle,
                interactive: true
            },
        ]
    },
    sf: {
        visible: false,
        label: 'Streamflow Monitoring',
        layers: [
            {
                url: huc8,
                style: getPlainStyle,
                interactive: false
            },
            {
                url: streamflow,
                style: getSFStyle,
                interactive: true
            },
        ]
    }
};

export const VARIABLES_INFO = {
    boundary: {
        title: 'Station Type',
        description: (
            <div>
                <b>Drainage</b>
                <p>
                    No description available
                </p>
                <b>HUC 8</b>
                <p>
                    No description available
                </p>
            </div>
        )
    },
    nutrient: {
        title: 'Nutrient Type',
        description: (
            <div>
                <p>
                    Nitrogen and Phosphorus are the two main nutrients that cause
                    the algal blooms that lead to hypoxia in the Gulf of Mexico.
                </p>
                <p>
                    Nitrogen – the main source of nitrogen is runoff from
                    agriculture, though there are other sources as well such
                    as urban areas and industry.
                </p>
                <p>
                    Phosphorus – the main source of phosphorus is wastewater
                    treatment, though there are other sources as well such
                    as erosion.
                </p>
            </div>
        )
    },
    yield: {
        title: 'Yield',
        description: (
            <div>
                Yield is a measure of nutrients lost per unit area. This measure is useful because
                it removes the influence of watershed size in a measurement so that different size
                watersheds may be compared.
            </div>
        )
    }
};
